import React from "react";
import PageWrapper from "../components/PageWrapper";
import Content1 from "../sections/manufacturing/Content1";
import Hero from "../sections/manufacturing/Hero";
import Wnapa from "../sections/manufacturing/Wnapa";
import Call from "../sections/manufacturing/Call";
import Testimonial from "../sections/manufacturing/Testimonial";
const manuSol = () => {
  return (
    <>
      <PageWrapper footerDark headerDark>
        <Hero />
        <Content1 />
        <Testimonial />
        <Wnapa />
        <Call />
      </PageWrapper>
    </>
  );
};
export default manuSol;
