import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Section, Box, Text } from "../../components/Core";
import { device } from "../../utils";
import imgAuthor from "../../assets/image/jpeg/author.jpg";

const SectionStyled = styled(Section)``;

const Author = styled(Box)`
  min-width: 271px;
  max-width: 271px;
  min-height: 271px;
  max-height: 271px;
  border-radius: 500px;
  overflow: hidden;
  margin-bottom: 30px;
  @media ${device.lg} {
    margin-bottom: 0;
  }
`;

const Fact = () => (
  <>
    {/* <!-- Fact section 1 --> */}
    <SectionStyled bg="#f6f6f8">
      <Container>
        <Row>
          <Col xs="12" className="mb-5">
            <div className="text-center">
              <Title>
                Is Napa Vertical Your Answer to Asset Productivity Enhancement?
              </Title>
            </div>
          </Col>
          <Col lg="4" className="offset-lg-1 mb-4 mb-lg-0">
            <Author>
              <img src={imgAuthor} alt="Author" />
            </Author>
          </Col>
          <Col lg="7" className="pr-lg-5">
            <div className="author-text">
              <Text variant="small" my={4}>
                Unpredictability is the biggest threat to a manufacturing
                process, and Napa Vertical’s manufacturing maintenance
                management software is the answer to turning unpredictable risks
                into optimal sustainability solutions.
              </Text>
              <Text variant="small">
                Our cloud-based management software offers a seamless solution
                for you and your business to stay on top of all maintenance
                tasks at all times, keeping inter- and intra-business
                communication channels open, transparent, and manageable.
              </Text>
              <Text variant="small" mt={4}>
                {" "}
                Take advantage of these organized systems today and reap the
                benefits of reduced costs and increased turnarounds.{" "}
              </Text>
            </div>
          </Col>
        </Row>
      </Container>
    </SectionStyled>
  </>
);

export default Fact;
